import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { Storage } from '@ionic/storage';
import { Observable, Subscription } from 'rxjs';
import { CompletedModuleStatus, UserJourneyStatus } from '../_utils/custom-types';
import { ModuleEnum } from '../_utils/unums/module.enum';
import { SortPipe } from '../_pipes/sort.pipe';
import moment from 'moment';
import { UserGradeEnum } from '../_utils/enums/user-grade.enum';
import {
  SIMULATION_DEMO_PROGRESS_SETTINGS,
  SIMULATION_PROGRESS_SETTINGS
} from '../_utils/settings/simulation-progress.settings';
import { GenericFunctions } from '../_utils/generic.functions';
import { Router } from '@angular/router';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class UserJourneyService {
  private userJourneySubscription: Subscription;

  constructor(
    private firebaseService: FirebaseService,
    private storage: Storage,
    private sortPipe: SortPipe,
    private router: Router,
    private eventService: EventsService
  ) {
  }

  public async listenAndUpdateUserJourney(userId: string): Promise<void> {
    this.closeUserJourneySubscription();
    const user: any = await this.firebaseService.getDocument(`/users/${userId}`);
    if (!user) {
      return;
    }
    this.userJourneySubscription = this.firebaseService.valueChanges(`/user_journey/${user.companyLowercase}/${user.uid}`)
      .subscribe(journey => {
        this.storage.set('user_journey', journey);
      });
  }

  public closeUserJourneySubscription(): void {
    if (!this.userJourneySubscription) {
      return;
    }
    if (!this.userJourneySubscription?.closed) {
      this.userJourneySubscription.unsubscribe();
    }
  }

  public async updateJourneyStatus(status: UserJourneyStatus): Promise<void> {
    const user = await this.storage.get('user');
    return await this.firebaseService.setDocument(`/user_journey/${user.companyLowercase}/${user.uid}/journey/status`, status);
  }

  public async updateJourneyCurrentModule(module: ModuleEnum): Promise<void> {
    const user = await this.storage.get('user');
    if (!user) {
      return;
    }
    return await this.firebaseService.setDocument(`/user_journey/${user.companyLowercase}/${user.uid}/current_module`, module);
  }

  public async getJourneyStatus(): Promise<UserJourneyStatus> {
    const user = await this.storage.get('user');
    // @ts-ignore
    return await this.firebaseService.getDocument(`/user_journey/${user.companyLowercase}/${user.uid}/journey/status`);
  }

  public async updateUserJourney(key: string, data: any): Promise<void> {
    const user = await this.storage.get('user');
    if (!user) {
      return;
    }
    await this.firebaseService.updateDocument(`/user_journey/${user.companyLowercase}/${user.uid}`, key, data);
  }

  public async completeUserJourneyModule(module: ModuleEnum): Promise<void> {
    await this.updateUserJourney(`modules/${module}`, {
      completedStatus: 'done',
      end: moment().format('YYYY-MM-DDTHH:mm:ss')
    });
  }

  public async activeUserJourneyModule(module: ModuleEnum): Promise<void> {
    await this.updateUserJourney(`modules/${module}`, {
      status: 'active'
    });
  }

  public async updateUserJourneyModule(module: string, data: any): Promise<void> {
    const moduleData = await this.getUserJourneyModule(module);
    const payloadModule = {
      ...moduleData,
      ...data
    };
    await this.updateUserJourney(`modules/${module}`, payloadModule);
  }

  public async getUserJourneyModule(module: string): Promise<any> {
    const user = await this.storage.get('user');
    const oldModule: any = await this.firebaseService.getDocument(`/user_journey/${user.companyLowercase}/${user.uid}/${module}`);
    const newModule: any = await this.firebaseService.getDocument(`/user_journey/${user.companyLowercase}/${user.uid}/modules/${module}`);
    return {
      ...(oldModule || {}),
      ...(newModule || {})
    };
  }

  public async getUserJourney(key?: string): Promise<any> {
    const user = await this.storage.get('user');
    if (!user) {
      return;
    }
    let url = `/user_journey/${user.companyLowercase}/${user.uid}`;
    if (key) {
      url += `/${key}`;
    }
    return await this.firebaseService.getDocument(url);
  }

  public async getCompletedModuleStatus(moduleKey: ModuleEnum | string): Promise<CompletedModuleStatus> {
    if (await this.isDemo()) {
      return;
    }
    const userModule = await this.getUserJourneyModule(moduleKey);
    if (userModule?.completedStatus === 'done' || userModule?.completedStatus === 'complete') {
      return userModule.completedStatus;
    }
    if (!userModule?.grade) {
      return 'incomplete';
    }
    const isAvatarComplete = await this.isCompleteAvatarSimulationsModule(moduleKey);
    const isPeerComplete = await this.isCompletePeerSimulationModule(moduleKey);
    const isCompleteModule = isAvatarComplete && isPeerComplete;
    return isCompleteModule ? 'complete' : 'incomplete';
  }

  public async getNextModuleKey(): Promise<ModuleEnum> {
    const userJourneyModules = await this.getUserJourney('modules');
    let userJourneyModulesList = Object.keys(userJourneyModules).map(key => ({
      key,
      ...userJourneyModules[key]
    }));
    userJourneyModulesList = this.sortPipe.transform(userJourneyModulesList, 'index', 'asc');
    let nextModule;
    for (const module of userJourneyModulesList) {
      if (module.completedStatus !== 'done') {
        nextModule = module;
        break;
      }
    }
    return nextModule?.key;
  }

  public  activateModule = async (module: ModuleEnum): Promise<void>  =>{
    await this.activeUserJourneyModule(module);
    await this.updateJourneyCurrentModule(module);
  };

  public navigateModule = async (module: ModuleEnum): Promise<void> => {
    await this.router.navigate([module, 'courses']);
    this.eventService.setEvent('open-menu-module', ModuleEnum);
  };

  public async getUserGrade(module: ModuleEnum): Promise<UserGradeEnum> {
    if (await this.isDemo()) {
      return UserGradeEnum.beginner;
    }
    const userJourney = await this.storage.get('user_journey');
    return userJourney?.modules?.[module]?.grade;
  }

  public async validateSectionCompleted(module: string, section: string): Promise<any> {
    const user = await this.storage.get('user');
    const userJourney = await this.getUserJourneyModule(module);
    const today = moment().format();
    if (!userJourney[section]) {
      this.firebaseService.setDocument(`/user_journey/${user.companyLowercase}/${user.uid}/modules/${module}/${section}`, today);
    }
  }

  public async activeDefaultModule(userId: string): Promise<void> {
    const user: any = await this.firebaseService.getDocument(`/users/${userId}`);
    if (!user) {
      return;
    }
    const url = `/user_journey/${user.companyLowercase}/${userId}/modules`;
    const userModules = await this.firebaseService.getDocument(url);
    const module = Object.keys(userModules || {}).find(moduleKey => userModules[moduleKey].status);
    if (module) {
      return;
    }
    let currentModule = await this.getUserJourney('current_module');
    if (!currentModule) {
      const addedUser: any = await this.firebaseService
        .getDocumentByIndexData(`/added_users/${user.companyLowercase}`, 'email', user.email);
      currentModule = addedUser?.module;
    }
    await this.activeUserJourneyModule(currentModule);
  }

  public async getCompletedAvatarSimulations(module: ModuleEnum | string): Promise<any[]> {
    const user = await this.storage.get('user');
    let userSimulations;
    let avatarSimulations;
    let simulationProgressSettings;
    // eslint-disable-next-line prefer-const
    [userSimulations, avatarSimulations, simulationProgressSettings] = await Promise.all([
      this.firebaseService.getDocumentsListOnce(`user_simulations/${module}/${user.uid}`),
      this.firebaseService.getDocumentsListOnce(`simulations/${module}`),
      this.getSimulationProgressSetting()
    ]);
    return userSimulations
      .filter(simulation => Object.keys(simulation).some(attempt => simulation[attempt].scoring >= simulationProgressSettings.score))
      .map(simulation => ({...simulation, level: avatarSimulations.find(x => x.id === simulation.id).level}));
  }

  public async isCompleteAvatarSimulationsModule(module: ModuleEnum | string): Promise<boolean> {
    const userJourney = await this.storage.get('user_journey');
    const completedAvatarSimulations = await this.getCompletedAvatarSimulations(module);
    const grade = userJourney.modules[module].grade;
    let isCompleteAvatarSimulation = true;
    const simulationProgressSettings = await this.getSimulationProgressSetting();
    for (const progressSetting of simulationProgressSettings.avatar.find(x => x.userGrade === grade).config) {
      const completedAvatarSimulationsByLevel = completedAvatarSimulations.filter(x => x.level === progressSetting.level);
      if (completedAvatarSimulationsByLevel.length < progressSetting.value) {
        isCompleteAvatarSimulation = false;
        break;
      }
    }
    return isCompleteAvatarSimulation;
  }

  public async isCompletePeerSimulationModule(module: ModuleEnum | string): Promise<boolean> {
    const userJourney = await this.storage.get('user_journey');
    const userModule = userJourney.modules[module];
    const peerSettings = await this.getModuleSimulationProgressPeerSetting(module);
    return (userModule?.simulations_peers || 0) >= (peerSettings?.value || 0);
  }

  public getSimulationProgressSetting = async (): Promise<{ [key: string]: any; score: number }> => {
    if (await this.isDemo()) {
      return SIMULATION_DEMO_PROGRESS_SETTINGS;
    }
    const user = await this.storage.get('user');
    const companyId = user.companyId;
    const simulationsConfig = SIMULATION_PROGRESS_SETTINGS;
    const auxSimulationsConfig: any = await this.firebaseService.getDocument(`companies/${companyId}/settings/simulations`);
    return {
      avatar: auxSimulationsConfig?.avatar || simulationsConfig.avatar,
      peer: auxSimulationsConfig?.peer || simulationsConfig.peer,
      score: !GenericFunctions.isNullOrUndefined(auxSimulationsConfig?.score) ? auxSimulationsConfig?.score : simulationsConfig.score
    };
  };

  public async getModuleSimulationProgressPeerSetting(module: ModuleEnum | string): Promise<any> {
    const simulationProgressSettings = await this.getSimulationProgressSetting();
    const userJourney = await this.storage.get('user_journey');
    const userModule = userJourney.modules[module];
    return simulationProgressSettings.peer.find(x => x.userGrade === userModule.grade);
  }

  public async getCurrentModule(): Promise<string> {
    const userJourney = await this.storage.get('user_journey');
    if (userJourney?.current_module) {
      return userJourney?.current_module;
    }
    let currentModule = await this.getUserJourney('current_module');
    if (!currentModule) {
      const user = await this.storage.get('user');
      const addedUser: any = await this.firebaseService
        .getDocumentByIndexData(`/added_users/${user.companyLowercase}`, 'email', user.email);
      currentModule = addedUser?.module;
    }
    return currentModule;
  }

  public async isDemo(): Promise<boolean> {
    const userJourney = await this.storage.get('user_journey');
    return !!userJourney?.demo;
  }

  public async increaseCounterSimulationsP2P(module: ModuleEnum | string): Promise<void> {
    if (await this.isDemo()) {
      return;
    }
    const userJourneyModule = await this.getUserJourneyModule(module);
    const simulationPeers = userJourneyModule.simulations_peers ? (userJourneyModule.simulations_peers + 1) : 1;
    await this.updateUserJourneyModule(module, {['simulations_peers']: simulationPeers});
  }

  public async setSessionLastLogin(userId: string): Promise<void> {
    const user: any = await this.firebaseService.getDocument(`/users/${userId}`);
    if (!user) {
      return;
    }
    await this.firebaseService.setDocument(`/user_journey/${user.companyLowercase}/${userId}/last_login`, moment().format('YYYY-MM-DD'));
  }

  public async unlockStatusVG(userJourney: any): Promise<void> {
    if (userJourney?.journey?.status === 'lockedVG') {
      await this.updateJourneyStatus('unlocked');
    }
    await this.storage.remove('guided_visit');
  }

  public updateJourney(uid: string, companyKey: string): Observable<any> {
    return this.firebaseService.valueChanges(`user_journey/${companyKey}/${uid}`);
  }

  public async updateUserJourneyDocument(path: string, userId: string, data: any): Promise<void> {
    await this.firebaseService.updateDocument(path, userId, data);
  }

  public async updateUserJourneyByCompany(company: string, userId: string, data: any): Promise<void> {
    try {
      await this.firebaseService.updateDocument(`user_journey/${company}`, userId, data);
    } catch (error) {
      console.error('Error updating user journey by company:', error);
      throw error;
    }
  }

  public async getUserJourneyFirstLogin(company: string, userId: string): Promise<any> {
    try {
      return await this.firebaseService.getDocument(`user_journey/${company}/${userId}/firstLogin`);
    } catch (error) {
      console.error('Error fetching user journey firstLogin:', error);
      throw error;
    }
  }
}
