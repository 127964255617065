import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryType } from '../_utils/custom-types';
import { ModuleEnum } from '../_utils/unums/module.enum';

@Pipe({
  name: 'dictionary'
})
export class DictionaryPipe implements PipeTransform {

  private dictionaryStrategy: any;

  constructor(
    private translateService: TranslateService
  ) {
    this.initDictionaryStrategy();
  }

  transform(key: string | number, dictionary: DictionaryType): string {
    let value = '';
    if (!key || !this.dictionaryStrategy[dictionary]) {
      return '';
    }
    value = this.dictionaryStrategy[dictionary](key);
    return this.translateService.instant(value);
  }


  private initDictionaryStrategy(): void {
    this.dictionaryStrategy = {
      language: (language: string): string => {
        let value;
        if (language === 'es-ES') {
          value = 'Español';
        } else if (language === 'en-US') {
          value = 'Ingles';
        }
        return value;
      },
      module: (module: string) => {
        let value: string;
        if (module === ModuleEnum.feedback) {
          value = 'Feedback';
        } else if (module === ModuleEnum.conversations) {
          value = 'Difficult Conversations';
        } else if (module === ModuleEnum.interviews) {
          value = 'Interviews';
        } else if (module === ModuleEnum.salesAndServices) {
          value = 'Sales & services';
        } else if (module === ModuleEnum.motivation) {
          value = 'Motivate from purpose';
        } else if (module === ModuleEnum.health_dialogues) {
          value = 'Valuable conversation with patients';
        } else if (module === ModuleEnum.telefonica) {
          value = 'Telefonica';
        }
        return value;
      },
      avatarGender: (gender: string) => {
        let value: string;
        if (gender === 'male') {
          value = 'Male';
        } else if (gender === 'female') {
          value = 'Female';
        }
        return value;
      },
      moduleCertificate: (module: string) => {
        let value: string;
        if (module === ModuleEnum.feedback) {
          value = 'Feedback for success';
        } else if (module === ModuleEnum.conversations) {
          value = 'Difficult conversations';
        }
        return value;
      },
      simulationLevel: (level: number) => {
        let value: string;
        if (level === 1) {
          value = 'Easy';
        } else if (level === 2) {
          value = 'Medium';
        } else if (level === 3) {
          value = 'Difficult';
        }
        return value;
      }
    };
  }

}
