import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private firebaseService: FirebaseService,
  ) {
  }

  public getUser = async (uid: string): Promise<any> => await this.firebaseService.getDocument(`users/${uid}`);
  public getUserProperty = async (uid: string, path: string): Promise<any> => await this.firebaseService.getDocument(`users/${uid}/${path}`);

  public async updateUserAvailability(companyLowercase: string, uid: string, availability: any): Promise<any> {
    const path = `user_journey/${companyLowercase}`;
    return this.firebaseService.updateDocument(path, uid, { availability });
  }

  public async getPeerName(peerId: string): Promise<string> {
    return this.firebaseService.getDocument(`users/${peerId}/displayName`);
  }

  public async updateAcceptedTyC(user: any, companyName: string, companyNameLowerCase: string, language: string): Promise<boolean> {
    try {
      await this.firebaseService.updateDocument('/users', user.uid, {
        termsAccepted: moment().format(),
        firstLogin: moment().format(),
        company: companyName,
        companyLowercase: companyNameLowerCase,
        companyId: user.companyId,
        email: user.email,
        lang: language,
        role: user.role || null,
        department: user.department ? user.department.toString() : null
      });
      return true;
    } catch (error) {
      console.error('Error updating terms and conditions:', error);
      throw error;
    }
  }

  public async updateUser(uid: string, userData: any): Promise<void> {
    try {
      await this.firebaseService.updateDocument('users', uid, userData);
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  }

  public async getAddedUserByEmail(companyLowerCase: string, email: string): Promise<any> {
    try {
      return await this.firebaseService.getDocumentByIndexData('/added_users/' + companyLowerCase, 'email', email);
    } catch (error) {
      console.error('Error fetching added user by email:', error);
      throw error;
    }
  }

  public async getAddedUserByCompany(company: string, email: string): Promise<any> {
    try {
      return await this.firebaseService.getDocumentByIndexData('added_users/' + company, 'email', email);
    } catch (error) {
      console.error('Error fetching added user by company:', error);
      throw error;
    }
  }
}
